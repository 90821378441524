.InovuaReactDataGrid,
.InovuaReactDataGrid--theme-default-light,
.InovuaReactDataGrid__row,
.InovuaReactDataGrid__cell,
.InovuaReactDataGrid__header,
.InovuaReactDataGrid__column-header,
.InovuaReactDataGrid__header-wrapper__fill {
  @apply !border-0 !bg-transparent;
}

.InovuaReactDataGrid__row-cell-wrap {
  @apply !rounded-[3px] !bg-tableRowBg;
  clip-path: inset(4px 0px 4px 0px round 3px) !important;
}

.choose-tracks-table .InovuaReactDataGrid__row-cell-wrap {
  @apply !rounded-[3px] !bg-tableRowBg;
  clip-path: none !important;
  background-color: transparent !important;
}

.InovuaReactDataGrid__column-header__content {
  @apply !text-[.625rem] !font-normal text-white/60 hover:!text-white/80;
}

.choose-tracks-table .InovuaReactDataGrid__column-header__content {
  @apply mb-4 border-b border-white/10 !pb-4;
}

.choose-tracks-table .inovua-react-virtual-list__view-container {
  @apply !mt-2;
}

.InovuaReactDataGrid__cell__content {
  @apply !text-[0.75rem] !font-normal !text-white;
}
.InovuaReactDataGrid__cell,
.InovuaReactDataGrid__column-header__content {
  @apply !pl-3 !pr-0;
}

.choose-tracks-table .InovuaReactDataGrid__column-header__content,
.choose-tracks-table .InovuaReactDataGrid__cell {
  @apply !pl-0 !pr-0;
}

.InovuaReactDataGrid__row-active-borders {
  @apply opacity-0;
}
