@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply dark bg-background text-foreground;
  }

  :root {
    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));

    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 232 96% 62%;
    --primary-foreground: 210 40% 98%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}

@responsive {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .show-scrollbar {
    -ms-overflow-style: auto; /* IE and Edge */
    scrollbar-width: auto; /* Firefox */
  }

  .show-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .show-scrollbar::-webkit-scrollbar:vertical {
    width: 12px;
  }

  .show-scrollbar::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  .show-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 9999px;
    background-clip: content-box; /* Makes the background only apply within the border */
  }

  .show-scrollbar::-webkit-scrollbar-track {
    border-radius: 9999px;
    background-color: rgba(255, 255, 255, 0.15);
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  color-scheme: dark;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

#__next {
  height: 100%;
}

body {
  color: rgb(255, 255, 255);
}

img {
  user-select: none;
}

@keyframes progressAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -28.28px 0;
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.breadcrumbs > * {
  padding-left: 0.4em;
}

.breadcrumbs > *::before {
  content: '>';
  padding-right: 0.4em;
  font-size: 60%;
  line-height: 0;
  display: inline-block;
  transform: translateY(-2px);
  opacity: 50%;
  font-weight: bold;
}

.breadcrumbs > :first-child {
  padding-left: 0;
}
.breadcrumbs > :first-child::before {
  content: '';
  display: none;
}

.progress_bar {
  width: 200px;
  height: 20px;
  background: linear-gradient(to bottom, #ddd, #e8e8e8, #eaeaea, #e4e4e4);
  border-radius: 10px;
  border: solid 1px #ccc;
  overflow: hidden;
}

.progress {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: #4caf50;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.1) 75%,
    transparent 75%,
    transparent
  );
  background-size: 28.28px 28.28px;
  background-position: 0px 0px;
  animation: progressAnimation 2s linear infinite;
  transform-origin: left center;
  transition: transform 0.3s ease-in-out;
}

@keyframes progressAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -28.28px 0;
  }
}

.tham-inner {
  border-radius: 0px;
}
.tham-inner::before {
  border-radius: 0px;
}
.tham-inner::after {
  border-radius: 0px;
}

.background-image {
  background-image: url('/background-isolated.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: rgb(36, 36, 57);
}

/* remove arrows from number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  appearance: textfield;
}

/* audio player styles */

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  /* Note: due to a limitation with the html audio element, the background-color cannot have any opacity other than 1 */
  background-color: rgb(50, 50, 50);
  /* background-color: red; */
}

.grey-audio-background audio::-webkit-media-controls-play-button,
.grey-audio-background audio::-webkit-media-controls-panel {
  background-color: green !important;
}

.modal-container {
  width: round(to-zero, 100vh, 1px);
  height: round(to-zero, 100vh, 1px);
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.remove-input-ring {
  input {
    @apply !outline-0 !ring-0;
  }
}

/* Input Styles from deprecated distro */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  color: white;
  -webkit-text-fill-color: white;
  caret-color: white;

  font-size: 1rem !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif !important;
  line-height: 1.5rem !important;

  /* It's impossible to modify the background-color of the webkit autofill, so here is a cheap work-around. */
  -webkit-box-shadow: 0 0 0px 1000px #262626 inset;
  box-shadow: 0 0 0px 1000px #262626 inset;

  border-width: 7px;
  border-color: #262626;
}

input:-webkit-autofill:focus {
  background-color: green !important;
  background: green !important;
  box-shadow:
    0 0 0px 1px rgb(65, 86, 232),
    0 0 0px 1000px #262626 inset !important;
}

input:-webkit-autofill::first-line {
  font-size: 1rem !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif !important;
  line-height: 1.5rem !important;
}

input:-internal-autofill-selected {
  background-color: green !important;
  background: green !important;
}
