/* styles.css */
.SliderRoot {
  position: relative;
  display: flex;
  align-items: center;
}
.SliderRoot[data-orientation='vertical'] {
  flex-direction: column;
  width: 100%;
  height: 100%;
  height: 100px;
}

.SliderTrack {
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
}
.SliderTrack[data-orientation='vertical'] {
  width: 3px;
}

.SliderRange {
  position: absolute;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 9999px;
}
.SliderRange[data-orientation='vertical'] {
  width: 100%;
}

.SliderThumb {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 9999px;
}
